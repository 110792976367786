@import '../../../assets/styles/sass/variables/variable';

@mixin btnStyle ($bgColor, $color) {
  background-color: $bgColor;
  color: $color;
  &.outlined {
    // border: 1px solid $bgColor;
    border: 1px solid #8a1e08;
    // border-color: $bgColor;
    // color: $bgColor;
    color: #8a1e08;
    outline: none;
    background-color: transparent;

  }
}
.lable {
  border-radius: 24px;
  text-transform: capitalize;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 600;
  
  @include btnStyle($grey-300, $black);

  &.square{
    border-radius: 0;
  }
  &.light_green {
    @include btnStyle($primary-100, $black);
  }
  &.green {
    @include btnStyle($success-300, aliceblue);
  }
  &.dark_green {
    @include btnStyle($primary-default, aliceblue);
  }
  &.light_purple {
    @include btnStyle($purple-light, $black);
  }
  &.purple {
    @include btnStyle($purple-dark, $black);
  }
  &.light_pink {
    @include btnStyle($pink-light, $black);
  }
  &.pink {
    @include btnStyle($pink-dark, $black);
  }
  &.light_orange {
    @include btnStyle($orange-light, $black);
    }
  &.caution {
    @include btnStyle($caution-900, aliceblue);
  }
  &.warning {
    @include btnStyle($warning-300, aliceblue);
  }
  &.overDue {
    @include btnStyle($warning-900, aliceblue);
  }

  &.primary {
    background-color: $link-900;
  }
}

.icon_right {
  flex-direction: row-reverse;
}
