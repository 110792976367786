@import 'bootstrap/dist/css/bootstrap.min.css';

:root {
  --primary-color: #546c5f;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  /* 10px */
  font-size: 62.5%;
}

body {
  font-family: 'Poppins', sans-serif !important;
  max-width: 1550px;
  margin: 0 auto;
}



.scrollbar {
  scrollbar-color: #b4be9c #f5f5f5;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f6f6f6;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b4be9c;
}

.d-flex{
  display: flex;
}

.no-wrap{
  flex-wrap: nowrap;
}
.align-items-center{
  align-items: center;
} 
.justify-content-between{
  justify-content: space-between;
}

.row{
  display: flex;
  flex-wrap: wrap;
}
.col-md-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.mb-2 {
  margin-bottom: 0.5rem!important;
}

.pb-3 {
  padding-bottom: 1rem!important;
}

.py-4 {
  padding-bottom: 1.5rem!important;
  padding-top: 1.5rem!important;
}

.flex-grow-1 {
  flex-grow: 1!important;
}
.justify-content-center {
  justify-content: center!important;
}

.gap-3 {
  gap: 1rem!important;
}

.py-2 {
  padding-bottom: 0.5rem!important;
  padding-top: 0.5rem!important;
}

.w-100{
  width: 100% !important;
}

.h-100{
  height: 100% !important;
}

.flex-column{
flex-direction: column;
}

.pt-4 {
  padding-top: 1.5rem!important;
}

.px-3 {
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}