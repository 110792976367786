.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 6px;
  font-size: 12px;
  border: none;
  font-weight: 500;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn.primary {
  background-color: #546C5F;
  font-weight: 500;
  color: #ffff;
}

.btn.primary:hover {
  background-color: #455c52;
}

.btn.light {
  background-color: #F5F8EF;
}

.btn.bordered {
  background-color: transparent;
  font-weight: 500;
  border: 1px solid #546C5F;
  color: #3C4D43;
}

.btn.linkBtn {
  background-color: transparent;
  color: #3C4D43;
  font-weight: 400;
}

.btn.linkBtn:hover {
  background-color: transparent;
  color: #2f3d35;
}

.btn.default_link {
  background-color: transparent;
  color: #175299;
}

.btn.onlyIcon {
  padding: 0.7500em !important;
}

.btn.sm {
  padding: 0 !important;
}

.btn.md {
  padding: 8px !important;
}

.btn.rounded {
  border-radius: 24px;
}

.btn.icon_right {
  flex-direction: row-reverse;
}
